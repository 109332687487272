import React, { useEffect } from "react"
import Layout from "../components/Layout"
import Seo from "../components/SEO"
import BannerModuleWrapper from "../components/BannerModule/BannerModuleWrapper"
import { graphql } from 'gatsby'
import PerksModuleDove from "../components/PerksModule/PerksModuleDove"

import Perk from "../components/PerksModule/Perk"
import { useInView } from 'react-intersection-observer'
import { useSliderAttivita } from "../hooks/useSliderAttivita"

import BasicTextGalleryModule from "../components/BasicTextGalleryModule/BasicTextGalleryModule"
import CollapseModule from "../components/CollapseModule/CollapseModule"



export default function AttivitaPage({ data }) {

  const slider_attivita = useSliderAttivita()

  const camelize = (str) => {
    return str.replace(/(?:^\w|[A-Z]|\b\w|\s+)/g, function (match, index) {
      if (+match === 0) return ""; // or if (/\s+/.test(match)) for white spaces
      return index === 0 ? match.toLowerCase() : match.toUpperCase();
    });
  }

  //console.log("useSliderAttivita", slider_attivita)
  //console.log("testiAttivita", data.allWp.edges[0].node.attivitaSettings.attivita_contenuti.testiAttivita)

  /* const prepareImg = (obj) => {
    return {
      original: obj.localFile.childrenImageSharp[0].original.src,
      thumbnail: obj.localFile.children[0].resize.src,
      originalW: obj.localFile.childrenImageSharp[0].original.width,
      originalH: obj.localFile.childrenImageSharp[0].original.height,
    };
  }
  

  let imgs = slider_attivita.immagini.map(prepareImg); */


 


  const titles = ["Attività", "Attività", "Attività"]
  const subtitles = ["Campi estivi, Campi di volontariato", "Escursioni: a piedi, in bici e a cavallo", "Laboratori didattici"]
  const linkTexts = ["approfondisci", "scopri di più", "leggi di più"]
  const linkUrls = ["#campiEstivi", "#escursioni", "#laboratoriDidattici", "/contatti"]

  const classiAggiuntiveGallery = {
    "campi di volontariato": "double",
    "campi estivi": "double",
    "laboratori didattici": "single",
    "escursioni": "single",
  }

  return (
    <>
      <Seo title="Attività" />
      <Layout>
        {<BannerModuleWrapper
          max={2}
          titles={titles}
          subtitles={subtitles}
          linkTexts={linkTexts}
          images={data.allWp.edges[0].node.attivitaSettings.backgrounds_attivita.bgsAttivita}
          linkUrls={linkUrls}
        />}



        <PerksModuleDove>
          <Perk title="The Title" content="The content" />
        </PerksModuleDove>

        {/*  <BasicTextGalleryModule
          titolo={data.allWp.edges[0].node.attivitaSettings.attivita_contenuti.titoloSezioneAttivita}
          content={data.allWp.edges[0].node.attivitaSettings.attivita_contenuti.testiAttivita}
          images={slider_attivita[0].galleria}
        /> */}
        <div className="section ">
        <div className="container container-wrapper">
          <h2 className="subtitle-attivita">Le attivtà del Rifugio Cognolo</h2>
        {data.allWp.edges[0].node.attivitaSettings.attivita_contenuti.testiAttivita.map((item, index) => (
         /*  <BasicTextGalleryModule
            id={camelize(item.titolo)}
            key={index}
            titolo={item.titolo}
            content={item.paragrafo}
            images={item.galleria}
            classe_gallery={classiAggiuntiveGallery[item.titolo.toLowerCase()]}
          /> */
          <div  id={camelize(item.titolo)}>
          <CollapseModule
            id={camelize(item.titolo)+"-collapse"}
            key={index}
            titolo={item.titolo}
            content={item.paragrafo}
            images={item.galleria}
            classe_gallery={classiAggiuntiveGallery[item.titolo.toLowerCase()]}
            >
              
          
          </CollapseModule>
          </div>
        ))}
        </div>
        </div>

      </Layout>
    </>
  )
}



export const imagesWp = graphql`
{
  allWp {
    edges {
      node {
        attivitaSettings {
          attivita_contenuti {
            testiAttivita {
              paragrafo
              titolo
              galleria {
                localFile {
                  children {
                    ... on ImageSharp {
                      id
                      resize(height: 168, width: 300, cropFocus: CENTER) {
                        src
                      }
                    }
                  }
                  childrenImageSharp {
                    original {
                      src
                      width
                      height
                    }
                    resize(height: 168, width: 300) {
                      src
                    }
                  }
                  
                }
              }
            }
            titoloSezioneAttivita
          }
          backgrounds_attivita  {
            bgsAttivita {
              localFile {
                childrenImageSharp {
                  gatsbyImageData(
                    aspectRatio: 1.7
                    placeholder: BLURRED
                    formats: AUTO
                    layout: FULL_WIDTH
                  )
                }
              }
            }
          }
        }
      }
    }
  }
}
`