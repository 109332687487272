import * as React from "react"
import { CButton } from '@coreui/react'
import { CCollapse } from '@coreui/react'
import { CCard } from '@coreui/react'
import { CCardBody } from '@coreui/react'
import '@coreui/coreui/dist/css/coreui.min.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import { Gallery, Item } from 'react-photoswipe-gallery'


//
const CollapseModule = function (props) {

    /* console.log('Render banner', props) */
    //const image = getImage(props.images[this.state.imageNum].localFile.childrenImageSharp[0])
    const [visible, setVisible] = React.useState(false)
    const prepareImg = (obj) => {
        return {
          original: obj.localFile.childrenImageSharp[0].original.src,
          thumbnail: obj.localFile.childrenImageSharp[0].resize.src,
          originalW: obj.localFile.childrenImageSharp[0].original.width,
          originalH: obj.localFile.childrenImageSharp[0].original.height,
        };
      }
    console.log("data.images",props.images)
    let imgs = props.images.map(prepareImg);
    return (
        <div className="wrapper-collapse">
            <CButton href="#" onClick={(event) => {
                event.preventDefault()
                setVisible(!visible)
            }}>
                {props.titolo}
            </CButton>
            <CCollapse visible={visible}>
                <CCard className="mt-3">
                    <CCardBody>
                        <div className="content"  dangerouslySetInnerHTML={{ __html: props.content }}>

                        </div>
                        <div className={"gallery " + props.classe_gallery}>
                            { <Gallery >
                                {imgs.map(child => (

                                    <Item
                                        key={child.original}
                                        original={child.original}
                                        thumbnail={child.thumbnail}
                                        width={child.originalW}
                                        height={child.originalH}
                                    >
                                        {({ ref, open }) => (
                                            <img ref={ref} onClick={open} src={child.thumbnail} />
                                        )}
                                    </Item>
                                ))
                                }

                            </Gallery> }
                        </div>
                    </CCardBody>
                </CCard>
            </CCollapse>
        </div>

    )
}
export default CollapseModule